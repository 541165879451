import { retriableLazyImport } from '@gcx-si/vue-components/retriableLazyImport'

import type { RouteRecordRaw } from 'vue-router'

import { constants } from '@/globals'
import { useTenantAdminGuard } from '@/apps/service/guards/useTenantAdminGuard'

export default {
  path: '/service',
  beforeEnter: [
    useTenantAdminGuard(),
  ],
  component: retriableLazyImport(() => import('./layout/ServiceLayout.vue')),
  redirect: {
    name: constants.ROUTES_SERVICE_CUSTOMERS,
  },
  children: [
    {
      path: 'customers',
      component: retriableLazyImport(() => import('./pages/overview/Overview.vue')),
      name: constants.ROUTES_SERVICE_CUSTOMERS,
    },
    {
      path: 'firmware',
      component: retriableLazyImport(() => import('./pages/firmware/Firmware.vue')),
      name: constants.ROUTES_SERVICE_FIRMWARE,
    },
    {
      path: 'tariffs',
      component: retriableLazyImport(() => import('./pages/tariffs/Tariffs.vue')),
      name: constants.ROUTES_SERVICE_TARIFFS,
    },
    {
      path: 'debug/consumption',
      component: retriableLazyImport(() => import('../../shared/consumption/debug/DataConsumptionCalculator.vue')),
      name: constants.ROUTES_CONSUMPTION_DEBUG,
    },
    {
      path: 'debug/errors',
      component: retriableLazyImport(() => import('@/shared/error-handling/debug/ErrorTester.vue')),
      name: constants.ROUTES_ERROR_DEBUG,
    },
    {
      path: 'debug/onboarding',
      component: retriableLazyImport(() => import('@/shared/module/debug/DebugOnboardingFlow.vue')),
      name: constants.ROUTES_ONBOARDING_DEBUG,
    },
    {
      path: 'debug/rtu-serial',
      component: retriableLazyImport(() => import('@/shared/module/debug/DebugRTUSerial.vue')),
      name: constants.ROUTES_SERIAL_DEBUG,
    },
  ],
} as RouteRecordRaw
