import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faAngleDown,
  faAngleUp,
  faArrowDownShortWide,
  faArrowDownToLine,
  faArrowLeft,
  faArrowRight,
  faArrowRightFromBracket,
  faArrowRotateLeft,
  faArrowRotateRight,
  faArrowUpFromLine,
  faArrowUpRightFromSquare,
  faBook,
  faCameraViewfinder,
  faCaretUp,
  faCheck,
  faCircleCheck,
  faCircleNotch,
  faCircleXmark,
  faClock,
  faCloudArrowUp,
  faCodeCompare,
  faCopy,
  faDisplayCode,
  faEllipsis,
  faExclamationTriangle,
  faGear,
  faGrid2Plus,
  faHardDrive,
  faHome,
  faInfo,
  faInfoCircle,
  faKey,
  faLink,
  faLock,
  faMessageExclamation,
  faMicrochip,
  faNetworkWired,
  faPenToSquare,
  faPlus,
  faQrcode,
  faRepeat,
  faRouter,
  faSearch,
  faSimCard,
  faSparkles,
  faSpinnerThird,
  faThumbtack,
  faTrashCan,
  faUnlock,
  faUser,
  faWifiExclamation,
  faWrench,
  faXmark,
  faFilter, faUsbDrive
} from '@fortawesome/pro-regular-svg-icons'
import {
  faCircleArrowUp as faCircleArrowUpDuoTone,
  faCircleCheck as faCircleCheckDuoTone
} from '@fortawesome/pro-duotone-svg-icons'
import {
  faCircleCheck as faCircleCheckSolid, faCircleRight,
  faFilter as faFilterSolid,
  faCircleXmark as faCircleXmarkSolid
} from '@fortawesome/pro-solid-svg-icons'

export const icons = [
  faAngleDown,
  faAngleUp,
  faArrowDownShortWide,
  faArrowDownToLine,
  faArrowLeft,
  faArrowRight,
  faArrowRightFromBracket,
  faArrowRotateRight,
  faArrowRotateLeft,
  faArrowUpFromLine,
  faBook,
  faCameraViewfinder,
  faCaretUp,
  faCheck,
  faCircleArrowUpDuoTone,
  faCircleCheck,
  faCircleCheckDuoTone,
  faCircleNotch,
  faCircleXmark,
  faClock,
  faCloudArrowUp,
  faCodeCompare,
  faCopy,
  faDisplayCode,
  faEllipsis,
  faExclamationTriangle,
  faGear,
  faGrid2Plus,
  faHardDrive,
  faHome,
  faInfo,
  faInfoCircle,
  faKey,
  faLink,
  faLock,
  faMessageExclamation,
  faMicrochip,
  faNetworkWired,
  faPenToSquare,
  faPlus,
  faQrcode,
  faRepeat,
  faRouter,
  faSearch,
  faSimCard,
  faSparkles,
  faSpinnerThird,
  faThumbtack,
  faTrashCan,
  faUnlock,
  faUser,
  faWifiExclamation,
  faWrench,
  faXmark,
  faCircleCheckSolid,
  faCircleRight,
  faArrowUpRightFromSquare,
  faFilter,
  faFilterSolid,
  faCircleXmarkSolid,
  faUsbDrive,
]

library.add(...icons)
