import App from './App.vue'
import { baseSetup } from './baseSetup'

import { createBeforeSendHandler } from '@gcx-si/vue-components/createBeforeSendHandler'
import { monkeyPatchDedupe } from '@gcx-si/vue-components/monkeyPatchDedupe'
import { createBeforeSendTransactionHandler } from '@gcx-si/vue-components/createBeforeSendTransactionHandler'
import {
  init as initSentry,
  browserTracingIntegration,
  replayIntegration,
  extraErrorDataIntegration,
  httpClientIntegration
} from '@sentry/vue'
import { createApp } from 'vue'

import type { Integration } from '@sentry/types'

import { config } from '@/globals/config'
import Router from '@/router'
import { AuthenticationPlugin } from '@/shared/authentication'
import I18n from '@/App.i18n'

const app = createApp(App)

initSentry({
  app,
  dsn: 'https://6d4b3d10acfa43298fceffa6b0cf4045@o469121.ingest.sentry.io/6353229',
  // do not configure release here, see vite.config.ts
  logErrors: false,
  maxBreadcrumbs: 50,
  normalizeDepth: 10,
  replaysSessionSampleRate: 0.0,
  replaysOnErrorSampleRate: 1.0,
  tracesSampleRate: 0.2,
  environment: config.SENTRY_ENVIRONMENT,
  debug: false,
  beforeSend: createBeforeSendHandler(app, import.meta.env.DEV),
  beforeSendTransaction: createBeforeSendTransactionHandler(import.meta.env.DEV),
  sendDefaultPii: true,
  integrations: function (integrations) {
    monkeyPatchDedupe(integrations)

    return [
      ...integrations,
      httpClientIntegration(),
      extraErrorDataIntegration(),
      browserTracingIntegration({
        router: Router,
      }),
      replayIntegration({
        maskAllText: false,
        maskAllInputs: false,
        maxReplayDuration: 120_000,
        block: [
          '[data-sentry-block]',
        ],
      }),
    ] as Integration[]
  },
})

baseSetup(app)
  .use(I18n)
  .use(Router)
  .use(AuthenticationPlugin)
  .mount('#app')
