import { setUser as setSentryUser } from '@sentry/browser'
import axios from 'axios'
import { defineStore, storeToRefs } from 'pinia'
import { computed, ref, shallowRef, watch } from 'vue'

import type { RoleResponse, UpdateUserRequest, UserResponse } from '@/api'

import { useCustomerStore } from '@/shared/authentication'
import { config } from '@/globals'

export const useUserStore = defineStore('UserStore', () => {
  const user = ref<UserResponse>()
  const { customer } = storeToRefs(useCustomerStore())
  const isAdminUser = computed(() => user.value?.role.name === 'Admin')
  const isSpecialistUser = computed(() => user.value?.role.name === 'Specialist')
  const isTenantUser = computed(() => user.value?.type === 'Tenant')
  const isCustomerUser = computed(() => user.value?.type === 'Customer')
  const roles = shallowRef<RoleResponse[]>()

  async function getCustomerUserRoles(id: string) {
    const { data } = await axios.get<RoleResponse[]>(`${config.BACKEND_URL}/v1/customers/${id}/roles`)

    return data
  }

  async function getUser(id: string) {
    const { data } = await axios.get<UserResponse>(`${config.BACKEND_URL}/v1/users/${id}`)

    return data
  }

  async function deleteUser(id: string) {
    await axios.delete(`${config.BACKEND_URL}/v1/users/${id}`)
  }

  async function updateUser(id: string, payload: UpdateUserRequest) {
    const { data: updatedUser } = await axios.patch<UserResponse>(`${config.BACKEND_URL}/v1/users/${id}`, payload)

    if (updatedUser.id === user.value?.id) {
      user.value = updatedUser
    }

    return updatedUser
  }

  async function getMe() {
    const { data } = await axios.get<UserResponse>(`${config.BACKEND_URL}/v1/users/me`)

    user.value = data

    return data
  }

  async function ensureRolesLoaded() {
    if (!roles.value) {
      roles.value = await getCustomerUserRoles(customer.value?.id as string)
    }

    return roles.value
  }

  watch(user, (newUser) => {
    if (!newUser) {
      setSentryUser(null)
      return
    }

    setSentryUser({
      id: newUser.id,
      username: newUser.name,
      email: newUser.email,
      role: newUser.role.name,
      customerId: newUser.customerId,
    })
  })

  watch(customer, () => {
    roles.value = undefined
  })

  return {
    user,
    isAdminUser,
    isSpecialistUser,
    isTenantUser,
    isCustomerUser,
    roles,
    ensureRolesLoaded,
    getUser,
    deleteUser,
    updateUser,
    getMe,
  }
})
