import type { NavigationGuard } from 'vue-router'
import type { SingleCustomer } from '@/shared/authentication'

import { constants } from '@/globals'
import { useUserStore } from '@/shared/authorization'
import { useCustomerStore } from '@/shared/authentication'

export function useTenantAdminGuard(): NavigationGuard {
  return async () => {
    const { getCustomers } = useCustomerStore()
    const { isCustomerUser } = useUserStore()

    if (isCustomerUser) {
      const customers: SingleCustomer[] = await getCustomers()

      return { name: constants.ROUTES_MODULES, params: { customerId: customers[0].id } }
    }
  }
}
