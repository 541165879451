import axios from 'axios'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

import type { Customer } from '@/model/settings'

import { config } from '@/globals'

export type SingleCustomer = {
  id: string
  name: string
}

export const useCustomerStore = defineStore('CustomerStore', () => {
  const customer = ref<Customer>()
  const tariffs = computed(() => (customer.value?.tariffs ?? []).sort((a, b) => a.rank - b.rank))
  const experimentalUi = computed(() => customer.value?.experimentalUiFeaturesEnabled)

  async function getCustomers(): Promise<SingleCustomer[]> {
    const { data } = await axios.get(`${config.BACKEND_URL}/v1/customers?page=0&size=999&sort=name`)

    return data.content
  }

  async function getCustomer(id: string): Promise<Customer> {
    const { data } = await axios.get(`${config.BACKEND_URL}/v1/customers/${id}`)

    return data
  }

  async function resetCustomer(id: string): Promise<void> {
    return await axios.delete(`${config.BACKEND_URL}/v1/customers/reset/${id}`)
  }

  async function setActiveCustomer(id: string) {
    if (customer.value?.id === id) {
      return
    }

    customer.value = await getCustomer(id)
  }

  function clearActiveCustomer() {
    customer.value = undefined
  }

  return {
    customer,
    tariffs,
    experimentalUi,
    setActiveCustomer,
    clearActiveCustomer,
    getCustomers,
    getCustomer,
    resetCustomer,
  }
})
