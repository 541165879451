import { retriableLazyImport } from '@gcx-si/vue-components/retriableLazyImport'

import type { RouteRecordRaw } from 'vue-router'

import { constants } from '@/globals'

export default {
  path: 'bus-configurations',
  meta: {
    navigation: {
      labelKey: 'app.bus_configuration.pages.overview.title',
      targetName: constants.ROUTES_BUS_CONFIGURATIONS,
    },
  },
  children: [
    {
      path: '',
      component: retriableLazyImport(() => import('./pages/Overview.vue')),
      name: constants.ROUTES_BUS_CONFIGURATIONS,
    },
    {
      path: ':busConfigurationId',
      component: retriableLazyImport(() => import('./pages/Details.vue')),
      name: constants.ROUTES_BUS_CONFIGURATION,
    },
    {
      path: 'create',
      component: retriableLazyImport(() => import('./pages/Details.vue')),
      name: constants.ROUTES_BUS_CONFIGURATION_CREATE,
    },
  ],
} as RouteRecordRaw
