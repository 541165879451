import { retriableLazyImport } from '@gcx-si/vue-components/retriableLazyImport'

import {
  BusConfigurationsRoutes,
  CloudAdapterRoutes,
  DeviceTypesRoutes,
  GeneralRoutes,
  InstallationsRoutes, MainUserRoutes, ModulesRoutes, SettingsRoutes
} from '@/apps'

export const CustomerRoutes = {
  path: '/:customerId',
  component: retriableLazyImport(() => import('@/shared/layout/Layout.vue')),
  children: [
    BusConfigurationsRoutes,
    CloudAdapterRoutes,
    DeviceTypesRoutes,
    GeneralRoutes,
    InstallationsRoutes,
    MainUserRoutes,
    ModulesRoutes,
    SettingsRoutes,
  ],
}
