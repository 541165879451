import { setUser as setSentryUser } from '@sentry/browser'
import { WebAuth } from 'auth0-js'
import axios from 'axios'
import { computed, ref, shallowRef } from 'vue'
import { defineStore } from 'pinia'
import localforage from 'localforage'
import { jwtDecode } from 'jwt-decode'

import type { Auth0Result, Auth0Error } from 'auth0-js'
import type { Milliseconds } from '@/utils/dataMaps'

import { config, constants } from '@/globals'

export const useAuthenticationStore = defineStore('AuthenticationStore', () => {
  const auth0 = new WebAuth({
    clientID: config.AUTH0_CLIENT_ID,
    domain: config.AUTH0_DOMAIN,
    audience: config.AUTH0_AUDIENCE,
    responseType: 'token',
    scope: 'openid',
    redirectUri: `${window.location.origin}/callback`,
  })
  const token = shallowRef<string | null>(null)
  const tokenExpirationTimestamp = shallowRef<Milliseconds>()
  const isAuthenticated = computed(() => !!token.value)
  const debug = ref({
    tokenUpdate: true, // You can disable this in the dev tools to test the behavior of getting no new valid token
    lastUpdate: '',
  })

  // FIXME keep for cypress for now, later use https://docs.cypress.io/guides/end-to-end-testing/auth0-authentication
  if (window.Cypress) {
    const instance = axios.create({
      baseURL: config.BACKEND_URL,
    })

    auth0.logout = () => instance.get(`${config.BACKEND_URL}/auth0/logout`)
    auth0.authorize = () => instance.get(`${config.BACKEND_URL}/auth0/authorize`)
    auth0.parseHash = () => instance.get(`${config.BACKEND_URL}/auth0/callback`)

    auth0.checkSession = async (options, cb) => {
      try {
        await instance.get(`${config.BACKEND_URL}/auth0/check-auth0-session`)

        cb(null, { accessToken: 'test-token' })
      } catch (error) {
        cb({ error: 'fake auth error' }, null)
      }
    }
  }

  function checkAuth0Session(): Promise<void> {
    return new Promise((resolve, reject) => {
      auth0.checkSession({}, async (error: null | Auth0Error, result: Auth0Result) => {
        if (error !== null) {
          await redirectToLogin()
          reject(new Error('auth0 error', { cause: error }))
          return
        }

        const newToken = result.accessToken as string

        if (debug.value.tokenUpdate) {
          // FIXME keep for cypress for now, later use https://docs.cypress.io/guides/end-to-end-testing/auth0-authentication
          const jwt = window.Cypress
            ? { exp: new Date().getTime() + 100_000 }
            : jwtDecode(newToken)

          token.value = newToken

          if (jwt.exp) {
            tokenExpirationTimestamp.value = jwt.exp * 1000
          }
        }

        debug.value.lastUpdate = (new Date()).toISOString()

        resolve()
      })
    })
  }

  function logout() {
    setSentryUser(null)

    token.value = null

    auth0.logout({
      returnTo: window.location.origin,
      clientID: config.AUTH0_CLIENT_ID,
      federated: true,
    })
  }

  async function redirectToLogin() {
    const url = new URL(window.location.href)
    await localforage.setItem(constants.LOCAL_STORAGE_AUTHENTICATION_REDIRECT, `${url.pathname}${url.hash}`)
    auth0.authorize()
  }

  function callback(): void {
    auth0.parseHash({ hash: window.location.hash }, (_, hash) => {
      if (hash) {
        token.value = hash.accessToken as string
      }
    })
  }

  return {
    token,
    tokenExpirationTimestamp,
    debug,
    isAuthenticated,
    redirectToLogin,
    callback,
    logout,
    checkAuth0Session,
  }
})
