import PrimeVue from 'primevue/config'
import ToastService from 'primevue/toastservice'
import PrimeTooltip from 'primevue/tooltip'
import PrimeRipple from 'primevue/ripple'
import FocusTrap from 'primevue/focustrap'
import { createPinia } from 'pinia'
import axios from 'axios'

import type { App } from 'vue'

import { TestDirective } from '@/shared/test'

import './icons'
import './yup'

import '@gcx-si/vue-components/main.css'

export function baseSetup(app: App) {
  const pinia = createPinia()

  axios.defaults.paramsSerializer = {
    ...axios.defaults.paramsSerializer,
    indexes: null,
  }

  app
    .use(pinia)
    .use(PrimeVue, { ripple: true })
    .use(ToastService)
    .directive('tooltip', PrimeTooltip)
    .directive('ripple', PrimeRipple)
    .directive('test', TestDirective)
    .directive('focustrap', FocusTrap)

  return app
}
